import axios from "axios"
import { jwtDecode } from "jwt-decode";

const btapi = axios.create({});
const btapiload = axios.create({});
const btapiopen = axios.create({});

async function checkJwt() {
    const token = localStorage.getItem("jwt")
    if (!token) {
        return
    }
    const jwtPayload = jwtDecode(token);

    // Get the current time and the expiration time in seconds
    const currentTime = Math.floor(Date.now() / 1000);
    const fifteenMinutes = 30 * 60;

    // Check if the token will expire in the next 15 minutes
    if (jwtPayload.exp && jwtPayload.exp <= currentTime + fifteenMinutes) {
        console.log("JWT Expiring Soon. Refreshing Token.")
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BTAPI}/api/v1/auth/refresh`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }}
            );

            // Update JWT
            localStorage.setItem("jwt", response.data.jwt)
        } catch (err) {
            console.log(err);
        }
    }
}

function apiLoadRequestStart(config) {
    // UPDATE: Add this code to show global loading indicator
    document.body.classList.add('loading-indicator');
    checkJwt()
    return config
}

function apiLoadRequestStartError(error) {
    return Promise.reject(error);
}

function apiLoadRequestEnd(response) {
    // UPDATE: Add this code to hide global loading indicator
    document.body.classList.remove('loading-indicator');
    return response;
}

function apiLoadRequestEndError(error) {
    document.body.classList.remove('loading-indicator');
    return Promise.reject(error);
}


function apiRequestStart(config) {
    // UPDATE: Add this code to show global loading indicator
    checkJwt()
    return config
}

function apiRequestStartError(error) {
    return Promise.reject(error);
}


const initializeApp = () => {

    btapi.defaults.baseURL = process.env.REACT_APP_BTAPI
    btapiload.defaults.baseURL = process.env.REACT_APP_BTAPI
    btapiopen.defaults.baseURL = process.env.REACT_APP_BTAPI

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code

    } else {
        // Prod build code

        // Removing console.log from prod
        console.log = () => {};

        // init analytics here
    }

    btapiload.interceptors.request.use(apiLoadRequestStart, apiLoadRequestStartError);
    btapiload.interceptors.response.use(apiLoadRequestEnd, apiLoadRequestEndError);
    btapi.interceptors.request.use(apiRequestStart, apiRequestStartError);
}

export const btApi = btapi
export const btApiLoad = btapiload
export const btApiOpen = btapiopen

export default initializeApp
